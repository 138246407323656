var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('v-row',[(!_vm.isNew && !_vm.elu)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.elu)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DropzoneComponent',{attrs:{"label":_vm.$t('dropzone.label_singular'),"max-files":1,"ratio":2 / 3},model:{value:(_vm.form.pictures),callback:function ($$v) {_vm.$set(_vm.form, "pictures", $$v)},expression:"form.pictures"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.last_name').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.last_name')},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.first_name').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.first_name')},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.position').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.position')},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('forms.biography')))]),_c('WYSISYWEditor',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('forms.delegations')))]),_c('WYSISYWEditor',{attrs:{"simple":""},model:{value:(_vm.form.delegations),callback:function ($$v) {_vm.$set(_vm.form, "delegations", $$v)},expression:"form.delegations"}})],1)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/elus"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }