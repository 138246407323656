<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !elu"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !elu"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <DropzoneComponent
                      v-model="form.pictures"
                      :label="$t('dropzone.label_singular')"
                      :max-files="1"
                      :ratio="2 / 3"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.last_name').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.last_name"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.last_name')"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.first_name').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.first_name"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.first_name')"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.position').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.position"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.position')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>{{ $t('forms.biography') }}</h3>
                    <WYSISYWEditor v-model="form.description" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>{{ $t('forms.delegations') }}</h3>
                    <WYSISYWEditor
                      v-model="form.delegations"
                      simple
                    />
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/elus"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../../mixins/serverError';
  import DropzoneComponent from '../../components/Dropzone';
  import WYSISYWEditor from '../../components/WYSIWYG';
  export default {
    name: 'EluForm',

    components: { WYSISYWEditor, DropzoneComponent },

    mixins: [serverError],

    data: () => ({
      form: {
        first_name: null,
        last_name: null,
        position: null,
        description: null,
        delegations: null,
        pictures: [],
      },
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        elu: 'elu/getElu',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewElu') : this.$t('pageTitles.Editing') + this.elu.full_name;
      },
    },

    created() {
      if (!this.isNew) {
        this.$store.dispatch('elu/fetchElu', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.elu,
              pictures: [this.elu.picture],
            };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, first_name, last_name, position, description, delegations } = this.form
          const picture_id = this.form.pictures && this.form.pictures[0] && this.form.pictures[0].id;
          const payload = { id, first_name, last_name, position, description, delegations, picture_id };
          const action = this.isNew ? 'elu/createElu' : 'elu/updateElu';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/elus');
            })
            .catch(error => this.displayErrors(error));
        }
      },
    },
  }
</script>
